<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div v-if="item">
        <b-form>
          <b-tabs
            v-model="tabIndex"
            pills
          >
            <b-tab
              :title="$t('catalog.item.create.tabs.general')"
              :title-link-class="linkClass(0)"
            >
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-form-group
                    :label="$t('catalog.item.create.fields.type')"
                    label-for="type"
                    :state="errors && errors.type ? false : null"
                    class="big_labels"
                  >
                    <div class="demo-inline-spacing">
                      <b-form-radio
                        v-for="itemTypes in productTypeOptions"
                        :key="itemTypes.type"
                        v-model="item.type"
                        :value="itemTypes.value"
                      >
                        {{ itemTypes.label }}
                      </b-form-radio>
                    </div>
                    <b-form-invalid-feedback v-if="errors && errors.type">
                      {{ errors.type[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    :label="$t('catalog.item.create.fields.avatar')"
                    label-for="avatar"
                    class="big_labels"
                  >
                    <!-- media -->
                    <b-media no-body>
                      <b-media-aside>
                        <b-link>
                          <b-img
                            ref="previewEl"
                            rounded
                            :src="$options.filters.mediaUrl(item, 'avatar', '150x150')"
                            height="80"
                          />
                        </b-link>
                        <!--/ avatar -->

                        <!-- reset -->
                        <b-button
                          v-if="item.avatar"

                          variant="outline-secondary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="item.avatar = null"
                        >
                          X
                        </b-button>
                        <!--/ reset -->
                      </b-media-aside>

                      <b-media-body class="mt-75 ml-75">
                        <InitFileManager
                          field="avatar"
                          type="product_avatar"
                          :multiple="false"
                          @fm-selected="selectedAvatar"
                        />
                        <b-form-text>
                          {{ $t('catalog.item.create.text.avatar') }}
                        </b-form-text>

                        <b-row
                          v-if="errors && errors.avatar"
                        >
                          <b-col cols="12">
                            <b-form-invalid-feedback :state="false">
                              {{ errors.avatar[0] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>
                        <b-row
                          v-if="errors && errors['avatar.type']"
                        >
                          <b-col cols="12">
                            <b-form-invalid-feedback :state="false">
                              {{ errors['avatar.type'][0] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>

                      </b-media-body>
                    </b-media>
                    <!--/ media -->
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    :label="$t('catalog.item.create.fields.link')"
                    label-for="link"
                    class="big_labels"
                  >
                    <b-form-input
                      id="link"
                      v-model="item.link"
                      :state="errors && errors.link ? false : null"
                    />
                    <b-form-text>
                      {{ $t('catalog.item.create.text.link') }}
                    </b-form-text>

                    <b-form-invalid-feedback v-if="errors && errors.link">
                      {{ errors.link[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-tabs class="lang_tabs">
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>
                  <b-row>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('catalog.item.create.fields.title')"
                        :label-for="'title.' + language.code"
                        class="big_labels"
                      >
                        <b-form-input
                          :id="'title.' + language.code"
                          v-model="item.title[language.code]"
                          :state="errors && errors['title.' + language.code] ? false : null"
                        />
                        <b-form-text>
                          {{ $t('catalog.item.create.text.title') }}
                        </b-form-text>
                        <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                          {{ errors['title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    :label="$t('catalog.item.create.fields.categories')"
                    label-for="categories"
                    :state="errors && errors.categories ? false : null"
                    class="big_labels"
                  >
                    <v-select
                      id="categories"
                      v-model="item.categories"
                      :multiple="true"
                      :clearable="false"
                      :options="categoryOptions"
                      :reduce="val => val.value"
                      :selectable="() => !item.categories || item.categories.length < 3"
                      input-id="categories"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.categories">
                      {{ errors.categories[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  class="hidden"
                >
                  <b-form-group
                    :label="$t('catalog.item.create.fields.language')"
                    label-for="language"
                    :state="errors && errors.language ? false : null"
                    class="big_labels"
                  >
                    <v-select
                      id="language"
                      v-model="item.language"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="languageOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="language"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.language">
                      {{ errors.language[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-tabs class="lang_tabs">
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>
                  <b-row>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('catalog.item.create.fields.description')"
                        :label-for="'description.' + language.code"
                        :state="errors && errors['description.' + language.code] ? false : null"
                        class="big_labels"
                      >
                        <b-form-textarea
                          :id="'description.' + language.code"
                          v-model="item.description[language.code]"
                          rows="8"
                          :placeholder="$t('catalog.item.create.text.description')"
                          :state="errors && errors['description.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['description.' + language.code]">
                          {{ errors['description.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    :label="$t('catalog.item.create.fields.sex')"
                    label-for="sex"
                    :state="errors && errors.sex ? false : null"
                    class="big_labels has_switcher"
                  >
                    <b-form-checkbox
                      v-model="item.sexSwitch"
                      checked="true"
                      class="custom-control-primary"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                    <b-input-group :class="{ 'disabled': !item.sexSwitch }">
                      <b-input-group-prepend
                        is-text
                        class="text-monospace boys"
                      >
                        Ч - {{ item.sex_ratio }}%
                      </b-input-group-prepend>
                      <b-form-input
                        id="sex_ratio"
                        v-model="item.sex_ratio"
                        type="range"
                        number
                        min="0"
                        max="100"
                        step="1"
                        :disabled="!item.sexSwitch"
                        :state="errors && errors.sex_ratio ? false : null"
                      />
                      <b-input-group-append
                        is-text
                        class="text-monospace girls"
                      >
                        Ж - {{ 100 - item.sex_ratio }}%
                      </b-input-group-append>
                    </b-input-group>
                    <b-form-invalid-feedback v-if="errors && errors.sex_ratio">
                      {{ errors.sex_ratio[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-tabs class="lang_tabs">
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>
                  <b-row>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('catalog.item.create.fields.source')"
                        :label-for="'source.' + language.code"
                        :state="errors && errors['source.' + language.code] ? false : null"
                        class="big_labels"
                      >
                        <b-form-textarea
                          :id="'source.' + language.code"
                          v-model="item.source[language.code]"
                          rows="8"
                          :placeholder="$t('catalog.item.create.text.source')"
                          :state="errors && errors['source.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['source.' + language.code]">
                          {{ errors['source.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    :label="$t('catalog.item.create.fields.prices')"
                    label-for="prices"
                    class="big_labels"
                  >
                    <b-form-text v-html="$t('catalog.item.create.text.prices')" />
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="repeater-form">
                <b-row
                  v-for="(price, index) in item.prices"
                  :id="'price' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.type.label')"
                      :label-for="'prices.' + index + '.type'"
                      :state="errors && errors['prices.' + index + '.type'] ? false : null"
                    >
                      <v-select
                        :id="'prices.' + index + '.type'"
                        v-model="price.type"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="listPriceTypes"
                        :selectable="val => !val.disabled"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'prices.' + index + '.type'"
                        :disabled="price.type === '1day'"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['prices.' + index + '.type']">
                        {{ errors['prices.' + index + '.type'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.price.label')"
                      :label-for="'prices.' + index + '.price'"
                    >
                      <b-form-input
                        :id="'prices.' + index + '.price'"
                        v-model="price.price"
                        :state="errors && errors['prices.' + index + '.price'] ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['prices.' + index + '.price']">
                        {{ errors['prices.' + index + '.price'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col
                    cols="12"
                    md="4"
                    class="mb-50"
                  >
                    <b-button
                      v-if="price.type !== '1day'"
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      block
                      @click="removePrice(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addPrice"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>
              <b-row
                v-if="errors && errors.prices"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.prices[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    :label="`${$t('catalog.item.create.fields.has_sale')}, %`"
                    label-for="sale"
                    :state="errors && errors.has_sale ? false : null"
                    class="big_labels has_switcher"
                  >
                    <b-form-checkbox
                      v-model="item.hasSale"
                      checked="true"
                      class="custom-control-primary"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                    <b-input-group :class="{ 'disabled': !item.hasSale }">
                      <b-input-group-prepend
                        is-text
                        class="text-monospace"
                      >
                        {{ item.sale }}%
                      </b-input-group-prepend>
                      <b-form-input
                        id="sale"
                        v-model="item.sale"
                        type="range"
                        number
                        min="0"
                        max="90"
                        step="1"
                        :disabled="!item.hasSale"
                        :state="errors && errors.sale ? false : null"
                      />
                    </b-input-group>
                    <b-form-text>
                      {{ $t('catalog.item.create.text.has_sale') }}
                    </b-form-text>
                    <b-form-invalid-feedback v-if="errors && errors.sale">
                      {{ errors.sale[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title-link-class="linkClass(1)">
              <template #title>
                <feather-icon
                  icon="DollarSignIcon"
                />
                {{ $t('catalog.item.create.tabs.hot') }}
              </template>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    :label="$t('catalog.item.create.fields.hots')"
                    label-for="hots"
                    class="big_labels"
                  >
                    <b-form-text>
                      {{ $t('catalog.item.create.text.hots') }}
                    </b-form-text>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="repeater-form">
                <b-row
                  v-for="(discount, index) in item.discounts"
                  :id="'discount' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <b-form-group
                      :label="$t('form.type.label')"
                      :label-for="'discounts.' + index + '.type'"
                      :state="errors && errors['discounts.' + index + '.type'] ? false : null"
                    >
                      <v-select
                        :id="'discounts.' + index + '.type'"
                        v-model="discount.type"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="listDiscountPriceTypes"
                        :selectable="val => !val.disabled"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'discounts.' + index + '.type'"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['discounts.' + index + '.type']">
                        {{ errors['discounts.' + index + '.type'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                  >
                    <b-form-group
                      :label="$t('form.price.label')"
                      :label-for="'discounts.' + index + '.price'"
                    >
                      <b-form-input
                        :id="'discounts.' + index + '.price'"
                        :ref="'discounts' + index + 'price'"
                        v-model="discount.price"
                        :type="`number`"
                        :step="`1`"
                        :state="errors && errors['discounts.' + index + '.price'] ? false : null"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['discounts.' + index + '.price']">
                        {{ errors['discounts.' + index + '.price'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                  >
                    <b-form-group
                      :label="$t('form.started_at.label')"
                      :label-for="'discounts.' + index + '.started_at'"
                      :state="errors && errors['discounts.' + index + '.started_at'] ? false : null"
                    >
                      <b-form-datepicker
                        :id="'discounts.' + index + '.started_at'"
                        v-model="discount.started_at"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['discounts.' + index + '.started_at']">
                        {{ errors['discounts.' + index + '.started_at'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                  >
                    <b-form-group
                      :label="$t('form.ended_at.label')"
                      :label-for="'discounts.' + index + '.ended_at'"
                      :state="errors && errors['discounts.' + index + '.ended_at'] ? false : null"
                    >
                      <b-form-datepicker
                        :id="'discounts.' + index + '.ended_at'"
                        v-model="discount.ended_at"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      />

                      <b-form-invalid-feedback v-if="errors && errors['discounts.' + index + '.ended_at']">
                        {{ errors['discounts.' + index + '.ended_at'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col
                    lg="2"
                    md="2"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeDiscount(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addDiscount"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>
              <b-row
                v-if="errors && errors.discounts"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.discounts[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            type="button"
            variant="outline-secondary"
            :to="{ name: 'account:products-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import router from '@/router'
import _ from 'lodash'

import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { mapGetters } from 'vuex'

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      tabIndex: 0,
      item: null,
      languages: [],
      categoryOptions: [],
      profileFile: null,
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
    listPriceTypes() {
      return _.map(this.priceTypeOptions, item => {
        item.disabled = _.some(this.item.prices, ['type', item.value])
        return item
      })
    },
    listDiscountPriceTypes() {
      return _.map(this.priceTypeOptions, item => {
        item.disabled = _.some(this.item.discounts, ['type', item.value])
        return item
      })
    },
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    await this.$http.get(`/api/user/products/${router.currentRoute.params.slug}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)
      })
      .catch(error => {
        this.item = undefined
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: error.message ?? this.$t('notifications.not_found'),
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
        router.replace({ name: 'products-index' })
      })

    if (!Object.keys(this.categoryOptions).length) {
      await this.$http.get('/api/promo/categories', {
        params: {
          type: 'product',
          status: 'enabled',
        },
      })
        .then(response => {
          this.categoryOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    }
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    linkClass(idx) {
      return (this.tabIndex === idx) ? ['bg-primary', 'text-light'] : ['bg-light', 'text-primary']
    },
    selectedAvatar(data) {
      this.item.avatar = data
    },
    onSubmit() {
      const data = {
        title: this.item.title,
        description: this.item.description,
        source: this.item.source,
        link: this.item.link,
        categories: this.item.categories,
        stickers: this.item.stickers,
        avatar: this.item.avatar,
        status: this.item.status,
        type: this.item.type,
        language: this.item.language,
        private: this.item.private,
        sex: this.item.sexSwitch ? 'enabled' : 'disabled',
        sex_ratio: this.item.sex_ratio,
        prices: this.item.prices,
        discounts: this.item.discounts,
        has_sale: this.item.hasSale ? 'enabled' : 'disabled',
        sale: this.item.sale ?? 0,
      }

      this.$http.put(`/api/user/products/${this.item.id}`, data)
        .then(() => {
          router.replace({ name: 'account:products-index', query: this.$route.query })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    addPrice() {
      this.item.prices.push({
        price: 1,
        type: null,
      })
    },
    removePrice(index) {
      this.item.prices.splice(index, 1)
    },
    addDiscount() {
      this.item.discounts.push({
        price: 1,
        started_at: new Date(),
        ended_at: new Date(),
        type: null,
      })
    },
    removeDiscount(index) {
      this.item.discounts.splice(index, 1)
    },
    transformData(item) {
      const data = {
        id: item.id,
        avatar: this.$options.filters.singleMedia(item.avatar),
        link: item.link,
        categories: item.categories,
        type: item.type,
        language: item.language,
        private: item.private,
        sex: item.sex,
        sexSwitch: item.sex === 'enabled',
        sex_ratio: item.sex_ratio,
        hasSale: item.has_sale === 'enabled',
        sale: item.sale ?? 0,
        title: {},
        description: {},
        source: {},
      }

      _.each(this.languages, language => {
        const translation = _.find(item.translations, { locale: language.code })

        data.title[language.code] = translation ? translation.title : null
        data.description[language.code] = translation ? translation.description : null
        data.source[language.code] = translation ? translation.source : null
      })

      data.prices = _.reduce(item.prices, (result, price) => {
        result.push({
          id: price.id,
          type: price.type,
          price: price.price,
        })
        return result
      }, [])

      data.discounts = _.reduce(item.discounts, (result, discount) => {
        result.push({
          id: discount.id,
          type: discount.type,
          price: discount.price,
          started_at: discount.started_at,
          ended_at: discount.ended_at,
        })
        return result
      }, [])

      data.categories = _.map(data.categories, 'id')

      return data
    },
    inputImageRenderer() {
      const { files } = this.$refs.refInputEl.$el

      const data = new FormData()
      data.append('disk', 'file-manager')
      data.append('path', '')
      data.append('overwrite', 1)
      for (let i = 0; i < files.length; i += 1) {
        data.append('files[]', files[i])
      }

      this.$http.post(`${process.env.VUE_APP_API_URL}/file-manager/upload`, data, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          accept: '*/*',
        },
      })
        .then(res => {
          const { names } = res.data.result

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Uploaded',
              icon: 'AlertCircleIcon',
              variant: 'success',
            },
          })

          setTimeout(() => {
            this.selectFmFiles(names)
          }, 500)
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    async selectFmFiles(files) {
      this.$http.get('/api/media', {
        params: {
          path: files,
        },
      })
        .then(response => {
          const { data } = response.data

          _.map(data, item => {
            item.meta_type = 'file'
            item.type = 'product_avatar'
            return item
          })

          this.selectedAvatar(data[0])
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.girls .input-group-text {
  background: var(--pink);
  color: var(--white);
}

.boys .input-group-text {
  background: var(--blue);
  color: var(--white);
}
</style>

<style lang="scss">
.lang_tabs ul.nav.nav-tabs {
  display: none !important;
}

.big_labels label {
  font-size: 1.6em;
}

.big_labels {
  margin-bottom: 2em !important;
}

.has_switcher {
  position: relative;
}

.has_switcher label {
  font-size: 1.6em;
  padding-left: 50px;
}

.has_switcher .custom-switch {
  margin: -30px 0 10px 0;
}
.has_switcher .custom-switch .custom-control-label .switch-text-left,
.has_switcher .custom-switch .custom-control-label .switch-text-right,
.has_switcher .custom-switch .custom-control-label .switch-icon-left,
.has_switcher .custom-switch .custom-control-label .switch-icon-right {
  line-height: 1;
}

.has_switcher .input-group-text {
  height: auto;
}

</style>
